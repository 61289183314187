<template>
  <div class="mt30" ref="box">
    <el-checkbox-group v-model="checkList">
      <waterfall :col="4" :data="imgList" :gutterWidth="0">
        <template>
          <div
            class="cell-item"
            v-for="(item, index) in imgList"
            :key="index"
            :class="{ active: checkList.indexOf(item.pid) != -1 }"
          >
            <el-checkbox class="checked" :label="item.pid" v-model="item.pid" />
            <router-link
              target="_blank"
              :to="{ path: '/chartlet/detail', query: { detailId: item.infoId } }"
            >
              <img ondragstart="return false" oncontextmenu="return false" :src="item.img" alt="" />
            </router-link>
            <!-- <div class="dow" @click.stop="download(item.pid)">
              <i class="iconfont">&#xe74d;</i>
            </div> -->
          </div>
        </template>
      </waterfall>
      <p class="t_c mt20 loadImg" :class="{ load: isLoading }">
        <img src="@/assets/image/loading.gif" alt="" />
      </p>
      <p
        class="t_c mt20 loadImg"
        :class="{ load: !isLoading && !listStatus.isData }"
      >
        没有更多了...
      </p>
    </el-checkbox-group>
  </div>
</template>

<script>
import { getToken } from "../../../utils/auth";
export default {
  name: "CollectChartletDetail",
  props: {
    collectList: {
      type: Array,
      default: () => [],
    },
    listStatus: {
      isData: true,
      isNull: false,
    },
  },
  data() {
    return {
      checkList: [],
      data: [],
      isLoading: false,
      imgList: [],
    };
  },
  methods: {
    clear() {
      this.imgList = [];
    },
    loadmore() {
      if (this.listStatus.isNull || !this.listStatus.isData) return;
      this.$emit("nextPage");
    },
    pushData() {
      this.imgList = this.imgList.concat(this.collectList);
      this.isLoading = false;
    },
    download(id) {
      if (!getToken()) {
        this.$login();
        return;
      }
			
			window.location.href =
				this.$ROOT_DIR +
				"/web/IntentGallery/downloadPicNew?id=" +
				id +
				"&token" +
				getToken();
    },
    checkAll() {
      if (this.collectList.length == this.checkList.length) {
        this.checkList = [];
      } else {
        this.collectList.forEach((item) => {
          if (this.checkList.indexOf(item.pid) == -1) {
            this.checkList.push(item.pid);
          }
        });
      }
    },
    clearAll() {
      this.checkList = [];
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const offsetTop = this.$refs.box.offsetHeight;
      if (scrollTop + windowHeight >= offsetTop && !this.isLoading) {
        //到了这个就可以进行业务逻辑加载后台数据了
        if (this.listStatus.isNull || !this.listStatus.isData) return;
        this.isLoading = true;
        this.loadmore();
      }
    },
  },
  mounted() {
    this.pushData();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    checkList: {
      handler(newVal) {
        this.$emit("isAll", newVal);
      },
      immediate: true,
    },
    collectList() {
      this.pushData();
    },
  },
};
</script>

<style scoped>
.loadImg {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.loadImg.load {
  visibility: visible;
  opacity: 1;
}
::v-deep .el-checkbox__label {
  display: none;
}
.checked {
  position: absolute;
  top: 0.1rem;
  /* right: 0.1rem; */
	right: 0.3rem;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s;
}
.dow {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  /* bottom: 0.38rem;
  right: 0.1rem; */
	bottom: 0.3rem;
	right: 0.3rem;
  font-size: 0.22rem;
  padding: 0.07rem 0.15rem;
  background: #fcfcfc;
  border-radius: 0.04rem;
  transition: 0.5s;
}
* {
  margin: 0;
}
.cell-item.active .checked {
  visibility: visible;
  opacity: 1;
}
.cell-item:hover .checked {
  visibility: visible;
  opacity: 1;
}
.cell-item:hover .dow {
  visibility: visible;
  opacity: 1;
}
.cell-item:hover {
  transform: translateY(-6px);
  box-shadow: none;
}
.cell-item {
  position: relative;
  /* width: 2.4rem; */
	padding: 0 0.2rem 0.2rem 0;
  cursor: pointer;
  transition: all 0.3s ease;
  /* margin-bottom: 0.2rem; */
}
.vue-waterfall {
  width: 101%;
}
.cell-item img {
  width: 100%;
	border-radius: 0.04rem;
}
.vue-waterfall::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.vue-waterfall::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>