<template>
  <div class="materList">
    <el-checkbox-group v-model="checkList">
      <div class="materItem" v-for="(item, index) in collectList" :key="index">
        <div
          class="ItemImg"
          :class="{ active: checkList.indexOf(item.pid) != -1 }"
        >
         <router-link
            target="_blank"
            :to="{ path: '/material/detail', query: { detailId: item.infoId } }"
          >
            <img :src="item.img" alt="" />
          </router-link>
          <el-checkbox class="checked" :label="item.pid" v-model="item.pid" />
        </div>
        <p class="txt-over c_33 f14 df_Yc">{{ item.title }}</p>
      </div>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "CollectMaterialDetail",
  props: ["collectList"],
  data() {
    return {
      checkList: [],
    };
  },
  methods: {
    checkAll() {
      if (this.collectList.length == this.checkList.length) {
        this.checkList = [];
      } else {
        this.collectList.forEach((item) => {
          if (this.checkList.indexOf(item.pid) == -1) {
            this.checkList.push(item.pid);
          }
        });
      }
    },
    clearAll() {
      this.checkList = [];
    },
  },
  watch: {
    checkList: {
      handler(newVal) {
        this.$emit("isAll", newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.df_Yc {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-checkbox__label {
  display: none;
}
.materList {
  margin-right: -0.2rem;
  margin-top: 0.2rem;
}
.materList:after {
  content: "";
  height: 0;
  width: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.ItemImg {
  width: 100%;
  height: 2.5rem;
  /*background: coral;*/
  overflow: hidden;
  position: relative;
}
.ItemImg img {
  width: 100%;
}
.ItemImg.active .checked {
  visibility: visible;
  opacity: 1;
}
.ItemImg:hover .checked {
  visibility: visible;
  opacity: 1;
}
.ItemImg .checked {
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  -webkit-transition: 500ms;
  -moz-transition: 500ms;
  -o-transition: 500ms;
}
.materItem {
  width: 31.75%;
  height: 3.1rem;
  border-radius: 0.08rem;
  float: left;
  margin-right: 0.15rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-bottom: 0.3rem;
  overflow: hidden;
  cursor: pointer;
  margin-right: 0.15rem;
}
.materItem p {
  flex: 1;
}
</style>