<template>
  <div class="detailBox">
    <div class="detailHeadBox">
      <div class="detailHead">
        <div class="df_Yc">
          <p class="f0404 f20 f_b mr10">{{ collectName }}</p>
          <p class="collectDx">收藏夹详情</p>
        </div>
        <div>
          <el-input
            v-model="searchKey"
            placeholder="请输入内容"
            @keyup.enter.native="search"
          >
            <i class="iconfont el-icon-search c_p" slot="suffix" @click="search"
              >&#xe67d;</i
            >
          </el-input>
        </div>
      </div>
      <div class="headStatus">
        <div class="f14 c_33 df_Yc">
          <i class="el-icon-arrow-left f18" @click="goBack"></i>
          <span class="mr30 c_p" @click="goBack">返回</span>
          <span v-if="type == 'course'">共{{ total }}个课程</span>
          <span v-if="type == 'material'">共{{ total }}个素材</span>
          <span v-if="type == 'photo'">共{{ total }}个图库</span>
          <span v-if="type == 'chartlet'">共{{ total }}个贴图</span>
          <span v-if="type == 'ps'">共{{ total }}个PS素材</span>
          <span v-if="type == 'su'">共{{ total }}个SU模型</span>
          <span v-if="type == 'max'">共{{ total }}个3D模型</span>
          <span v-if="type == 'schemeText'">共{{ total }}个方案文本</span>
        </div>
        <div class="df_Yc">
          <div class="allSelectDiv">
            <el-checkbox v-model="allSelect" @change="allSelectFun"
              >全选</el-checkbox
            >
          </div>
          <div
            class="cancelButton"
            :class="{ noSelect: !isSelect }"
            @click="cancelCollect"
          >
            取消收藏
          </div>
          <div
            class="cancelButton"
            :class="{ noSelect: !isSelect }"
            v-if="isSelect && folderList.length > 0"
            @click="dialogFormVisible1 = true"
          >
            移动至...
          </div>
          <div class="editBtn c_p" @click="showPopFun(collectName)">
            <i class="el-icon-edit"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="detailContent" style="padding-top: 0.2rem;">
      <CollectSuDetail
        @isAll="isAll"
        ref="suDetail"
        :collectList="collectList"
        v-if="type == 'su'"
      ></CollectSuDetail>
      <CollectCourseDetail
        @isAll="isAll"
        ref="courseDetail"
        :collectList="collectList"
        v-if="type == 'course'"
      ></CollectCourseDetail>
      <CollectMaterialDetail
        @isAll="isAll"
        ref="materialDetail"
        :collectList="collectList"
        v-if="type == 'material'"
      ></CollectMaterialDetail>
      <CollectPhotoDetail
        @isAll="isAll"
        @nextPage="nextPage"
        ref="photoDetail"
        :collectList="collectList"
        :listStatus="listStatus"
        v-if="type == 'photo'"
      ></CollectPhotoDetail>
			<CollectChartletDetail
        @isAll="isAll"
        @nextPage="nextPage"
        ref="chartletDetail"
        :collectList="collectList"
        :listStatus="listStatus"
        v-if="type == 'chartlet'"
      ></CollectChartletDetail>
      <CollectPsDetail
        @isAll="isAll"
        @nextPage="nextPage"
        ref="psDetail"
        :collectList="collectList"
        :listStatus="listStatus"
        v-if="type == 'ps'"
      ></CollectPsDetail>
      <CollectMaxDetail
          @isAll="isAll"
          @nextPage="nextPage"
          ref="maxDetail"
          :collectList="collectList"
          :listStatus="listStatus"
          v-if="type == 'max'"
      ></CollectMaxDetail>
      <CollectSchemeTextDetail
          @isAll="isAll"
          @nextPage="nextPage"
          ref="schemeTextDetail"
          :collectList="collectList"
          :listStatus="listStatus"
          v-if="type == 'schemeText'"
      ></CollectSchemeTextDetail>
      <div
        class="pagination"
        v-if="total && this.type != 'photo' && this.type != 'ps' && this.type != 'chartlet'"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="setPage"
          :total="total"
          :page-size="per_page"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogFormTitle"
      :visible.sync="dialogFormVisible"
      width="440px"
      height="231px"
      class="dialogBox"
    >
      <el-form>
        <el-form-item label="名称" label-width="50px">
          <el-input v-model="collectName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer popBottom">
        <div
          class="popBtn1"
          @click="
            (dialogFormVisible = false), (collectName = $route.query.name)
          "
        >
          <p>取消</p>
        </div>
        <div class="popBtn2" @click="submitCollectForm(collectName)">
          <p>确定</p>
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗 -->
    <el-dialog
      title="移动文件到其他文件夹"
      :visible.sync="dialogFormVisible1"
      width="440px"
      height="231px"
      class="dialogBox"
    >
      <el-form>
        <el-form-item label="文件夹" label-width="80px">
          <el-select v-model="changeId" placeholder="请选择文件夹">
            <el-option
              v-for="item in folderList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer popBottom">
        <div class="popBtn1" @click="dialogFormVisible1 = false">
          <p>取消</p>
        </div>
        <div class="popBtn2" @click="changeFolder(changeId)">
          <p>确定</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CollectSuDetail from "./componts/CollectSuDetail";
import CollectCourseDetail from "./componts/CollectCourseDetail";
import CollectMaterialDetail from "./componts/CollectMaterialDetail";
import CollectPhotoDetail from "./componts/CollectPhotoDetail";
import CollectChartletDetail from "./componts/CollectChartletDetail";
import CollectPsDetail from "./componts/CollectPsDetail";
import CollectMaxDetail from "./componts/CollectMaxDetail";
import CollectSchemeTextDetail from "./componts/CollectSchemeTextDetail";
import {
  getCollectList,
  deleteCollect,
  photoCollectDel,
  editFolder,
  editPhotoFolder,
  getCollectFolder,
  moveAll,
} from "../../Api";
import { getToken } from "../../utils/auth";

export default {
  name: "MyCollectDetail",
  components: {
    CollectSuDetail,
    CollectCourseDetail,
    CollectMaterialDetail,
    CollectPhotoDetail,
    CollectChartletDetail,
    CollectPsDetail,
    CollectSchemeTextDetail,
    CollectMaxDetail
  },
  data() {
    return {
      searchKey: "",
      allSelect: false,
      type: null,
      collectName: "",
      detailId: "",
      collectList: [],
      total: 0,
      isSelect: false,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogFormTitle: "添加文件夹",
      collectForm: {
        name: "",
      },
      folderList: [],
      changeId: "",
      page: "",
      per_page: 10,
      listStatus: {
        isData: true,
        isNull: false,
      },
    };
  },
  created() {
    if (this.$route.query) {
      this.detailId = this.$route.query.detailId;
      this.type = this.$route.query.type;
      this.collectName = this.$route.query.name;
      this.getCollectFolder(this.detailId);
    }
    this.getList();
  },
  methods: {
    nextPage() {
      this.page++;
      this.getList();
    },
    //  选定分页
    setPage(val) {
      this.page = val;
      this.getList();
    },
    //移动文件夹
    changeFolder(folderId) {
      if (!this.isSelect) return;
      let idStr = "";
      let fid = "";
      if (this.type == "su") {
        idStr = this.$refs.suDetail.checkList.join(",");
        fid = 561;
      } else if (this.type == "course") {
        idStr = this.$refs.courseDetail.checkList.join(",");
        fid = 6;
      } else if (this.type == "material") {
        idStr = this.$refs.materialDetail.checkList.join(",");
        fid = 7;
      } else if (this.type == "photo") {
        idStr = this.$refs.photoDetail.checkList.join(",");
        fid = 2381;
      }  else if (this.type == "chartlet") {
        idStr = this.$refs.chartletDetail.checkList.join(",");
        fid = 2205;
      } else if (this.type == "ps") {
        idStr = this.$refs.psDetail.checkList.join(",");
        fid = 1196;
      }else if (this.type == "max") {
        idStr = this.$refs.maxDetail.checkList.join(",");
        fid = 1393;
      }else if (this.type == "schemeText") {
        idStr = this.$refs.schemeTextDetail.checkList.join(",");
        fid = 1394;
      }
      moveAll({
        token: getToken(),
        folder_id: folderId,
        id: idStr,
        fid: fid,
      }).then((res) => {
        if (res.status === "200" && res.data.message === "moveSuccess") {
          if (this.type === "su") {
            this.$refs.suDetail.clearAll();
          } else if (this.type === "course") {
            this.$refs.courseDetail.clearAll();
          } else if (this.type === "material") {
            this.$refs.materialDetail.clearAll();
          } else if (this.type === "photo") {
            this.$refs.photoDetail.clearAll();
            this.$refs.photoDetail.clear();
          } else if (this.type === "chartlet") {
            this.$refs.chartletDetail.clearAll();
            this.$refs.chartletDetail.clear();
          } else if (this.type === "ps") {
            this.$refs.psDetail.clearAll();
            this.$refs.psDetail.clear();
          }else if (this.type === "max") {
            this.$refs.maxDetail.clearAll();
          }else if (this.type === "schemeText") {
            this.$refs.schemeTextDetail.clearAll();
          }
          this.dialogFormVisible1 = false;
          this.getList();
        }
      });
    },
		// 修改收藏夹名称
    submitCollectForm() {
      // 修改
      const data = {
        token: getToken(),
        id: this.$route.query.detailId,
        name: this.collectName,
      };
      if (this.activeName !== "2381" && this.activeName != "2205") {
        // 非图库收藏夹
        editFolder(data).then((res) => {
          if (res.status === "200" && res.data.message === "editSuccess") {
            this.$message.success("修改成功");
            this.dialogFormVisible = false;
            //将参数设置到地址栏
            const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
            query.name = this.collectName; //改变参数
            this.$router.push({ path: this.$route.path, query });
          }
        });
      } else {
        // 图库收藏夹
        editPhotoFolder(data).then((res) => {
          if (res.status === "200" && res.data.message === "editSuccess") {
            this.$message.success("修改成功");
            this.dialogFormVisible = false;
            //将参数设置到地址栏
            const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
            query.name = this.collectName; //改变参数
            this.$router.push({ path: this.$route.path, query });
          }
        });
      }
    },
    showPopFun(collectName) {
      this.dialogFormVisible = true;
      this.collectForm.name = collectName;
      this.dialogFormTitle = "修改文件夹";
    },
    isAll(data) {
      if (data.length > 0) {
        this.isSelect = true;
      } else {
        this.isSelect = false;
      }
      if (data.length == this.collectList.length && data.length != 0) {
        this.allSelect = true;
      } else {
        this.allSelect = false;
      }
    },
    getList() {
      let fid = "";
      if (this.type === "su") {
        fid = 561;
        this.per_page = 9;
      } else if (this.type === "course") {
        fid = 6;
        this.per_page = 12;
      } else if (this.type === "material") {
        fid = 7;
        this.per_page = 9;
      } else if (this.type === "photo") {
        fid = 2381;
        this.per_page = 20;
      } else if (this.type === "chartlet") {
        fid = 2205;
        this.per_page = 20;
      } else if (this.type === "ps") {
        fid = 1196;
        this.per_page = 20;
      }else if (this.type === "max") {
        fid = 1393;
        this.per_page = 9;
      }else if (this.type === "max") {
        fid = 1394;
        this.per_page = 9;
      }
      const data = {
        token: getToken(),
        id: this.detailId,
        key: this.searchKey,
        fid: fid,
        page: this.page,
        per_page: this.per_page,
      };
      getCollectList(data).then((res) => {
        this.total = res.data.total;
        this.collectList = res.data.data;
        if (res.data.total == 0) {
          this.listStatus.isNull = true;
          if (this.type == "photo" || this.type == "ps" || this.type == "chartlet") {
            this.$refs.psDetail&&this.$refs.psDetail.clear()
            this.$refs.photoDetail&&this.$refs.photoDetail.clear()
            this.$refs.chartletDetail&&this.$refs.chartletDetail.clear()
          }
        } else if (res.data.current_page == res.data.last_page) {
          this.listStatus.isData = false;
        }
      });
    },
		// 返回
    goBack() {
      if (this.type === "su") {
        this.$router.push({ path: "myCollect", query: { activeId: "561" } });
      } else if (this.type === "course") {
        this.$router.push({ path: "myCollect", query: { activeId: "6" } });
      } else if (this.type === "material") {
        this.$router.push({ path: "myCollect", query: { activeId: "7" } });
      } else if (this.type === "photo") {
        this.$router.push({ path: "myCollect", query: { activeId: "2381" } });
      } else if (this.type === "chartlet") {
        this.$router.push({ path: "myCollect", query: { activeId: "2205" } });
      } else if (this.type === "ps") {
        this.$router.push({ path: "myCollect", query: { activeId: "1196" } });
      }else if (this.type === "max") {
        this.$router.push({ path: "myCollect", query: { activeId: "1393" } });
      }else if (this.type === "schemeText") {
        this.$router.push({ path: "myCollect", query: { activeId: "1394" } });
      }
    },
    // 获取收藏夹列表
		getCollectFolder(detailId) {
      let fid = "";
      if (this.type === "su") {
        fid = 561;
      } else if (this.type === "course") {
        fid = 6;
      } else if (this.type === "material") {
        fid = 7;
      } else if (this.type === "photo") {
        fid = 2381;
      } else if (this.type === "chartlet") {
        fid = 2205;
      } else if (this.type === "ps") {
        fid = 1196;
      }else if (this.type === "max") {
        fid = 1393;
      }else if (this.type === "schemeText") {
        fid = 1394;
      }
      const data = {
        token: getToken(),
        fid: fid,
        key: "",
        page: 1,
        per_page: 100,
      };
      getCollectFolder(data).then((res) => {
        this.folderList = res.data.data.filter(function (item) {
          if (item.id != detailId) return item;
        });
      });
    },
    // 搜索
		search() {
      this.page = 1;
			if (this.type === "ps") {
				this.$refs.psDetail&&this.$refs.psDetail.clear()
      } else if (this.type === "photo") {
				this.$refs.photoDetail&&this.$refs.photoDetail.clear()
      } else if (this.type === "chartlet") {
				this.$refs.chartletDetail&&this.$refs.chartletDetail.clear()
      }
      this.getList();
    },
    allSelectFun() {
      if (this.type === "su") {
        this.$refs.suDetail.checkAll();
      } else if (this.type === "course") {
        this.$refs.courseDetail.checkAll();
      } else if (this.type === "material") {
        this.$refs.materialDetail.checkAll();
      } else if (this.type === "photo") {
        this.$refs.photoDetail.checkAll();
      } else if (this.type === "chartlet") {
        this.$refs.chartletDetail.checkAll();
      } else if (this.type === "ps") {
        this.$refs.psDetail.checkAll();
      } else if (this.type === "max") {
        this.$refs.maxDetail.checkAll();
      } else if (this.type === "schemeText") {
        this.$refs.schemeTextDetail.checkAll();
      }
    },
    //取消收藏
    cancelCollect() {
      if (!this.isSelect) return;
      let idStr = "";
      if (this.type == "su") {
        idStr = this.$refs.suDetail.checkList.join(",");
      } else if (this.type == "course") {
        idStr = this.$refs.courseDetail.checkList.join(",");
      } else if (this.type == "material") {
        idStr = this.$refs.materialDetail.checkList.join(",");
      } else if (this.type == "photo") {
        idStr = this.$refs.photoDetail.checkList.join(",");
      } else if (this.type == "chartlet") {
        idStr = this.$refs.chartletDetail.checkList.join(",");
      } else if (this.type == "ps") {
        idStr = this.$refs.psDetail.checkList.join(",");
      }else if (this.type == "max") {
        idStr = this.$refs.maxDetail.checkList.join(",");
      }else if (this.type == "schemeText") {
        idStr = this.$refs.schemeTextDetail.checkList.join(",");
      }
      this.$confirm("此操作将取消该收藏, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.type != "photo" && this.type != "chartlet") {
            const data = {
              token: getToken(),
              idStr: idStr,
              id: this.detailId,
            };
            deleteCollect(data).then((res) => {
              if (res.data.message == "delSuccess") {
                this.$message({
                  type: "success",
                  message: "取消成功!",
                });
								if (this.type == "ps") {
									this.$refs.psDetail.clear()
								}
                this.getList();
              }
            });
          } else {
            const data = {
              token: getToken(),
              pid: idStr,
              id: this.detailId,
            };
            photoCollectDel(data).then((res) => {
              if (res.data.message == "delSuccess") {
                this.$message({
                  type: "success",
                  message: "取消成功!",
                });
								if (this.type == "photo") {
									this.$refs.photoDetail.clear()
								} else{
									this.$refs.chartletDetail.clear()
								}
                this.getList();
              }
            });
          }
        })
        .catch(() => {});
    },
  },
  watch: {},
};
</script>
<style scoped>
::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
  color: #f46600;
}
::v-deep .el-input__suffix {
  right: 5px;
  transition: all 0.3s;
  justify-content: center;
  display: flex;
  align-items: center;
}
.editorName {
  width: 0.32rem;
  height: 0.32rem;
  margin-left: 0.15rem;
  cursor: pointer;
}

.headStatus >>> .el-button--primary {
  color: #040404;
  background-color: #eeeeee;
  border-color: #eeeeee;
  line-height: 0.3rem;
  width: 0.95rem;
}

.el-button {
  padding: 0;
}

.headStatus >>> .allSelectDiv {
  width: 0.94rem;
  height: 0.32rem;
  line-height: 0.32rem;
  text-align: center;
  background: #eeeeee;
  border-radius: 0.02rem;
  margin-right: 0.2rem;
}

.cancelButton {
  background: #f46600;
  border-radius: 0.02rem;
  line-height: 0.32rem;
  text-align: center;
  width: 0.96rem;
  height: 0.32rem;
  color: #ffffff;
  font-size: 0.14rem;
  margin-right: 0.2rem;
  cursor: pointer;
}
.cancelButton.noSelect {
  background: #c0c4cc;
}

.headStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.4rem;
}

.detailHead >>> .el-input {
  width: 2.2rem;
  font-size: 0.14rem;
}

.detailHead >>> .el-input__inner {
  height: 0.3rem;
  line-height: 0.3rem;
}

.detailHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.2rem;
  border-bottom: 0.02rem solid #eeeeee;
}

.detailHeadBox {
  background: #ffffff;
  border-radius: 0.04rem;
  padding: 0.2rem 0.3rem;
}

.collectDx {
  border-left: 1px solid #333333;
  padding-left: 0.1rem;
  color: #333333;
  font-size: 0.14rem;
}
.dialogBox >>> .el-dialog__title {
  width: 80px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #040404;
  opacity: 1;
}
.dialogBox >>> .el-dialog__header {
  border: 1px solid #eeeeee;
}
.dialogBox >>> .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 240px;
}
.dialogBox >>> .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  padding-left: 55px;
}
.dialogBox >>> .el-dialog__footer {
  padding: 0 20px;
  text-align: right;
  box-sizing: border-box;
  background: #f9f9f9;
}
.popBottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #f9f9f9;
  opacity: 1;
  border-radius: 4px;
}
.popBtn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
  margin-right: 20px;
}

.popBtn1 p {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  opacity: 1;
}

.popBtn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  background: #f46600;
  opacity: 1;
  border-radius: 4px;
}

.popBtn2 p {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
}
.editBtn {
  width: 0.32rem;
  height: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f46600;
}
.editBtn i {
  color: #ffffff;
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 0.1rem 0;
}
</style>