<template>
  <div class="courseList">
    <el-checkbox-group v-model="checkList">
      <div
        class="courseItem"
        :class="{ active: checkList.indexOf(item.pid) != -1 }"
        v-for="(item, index) in collectList"
        :key="index"
      >
        <div class="itemImg">
          <router-link
            target="_blank"
            :to="{ path: '/course/detail', query: { detailId: item.infoId } }"
          >
            <img :src="item.img" alt="" />
          </router-link>
        </div>
        <div class="itemBottom">
          <p class="txt-over c_33 f14">{{ item.title }}</p>
          <div class="botStatus">
            <p
              class="status"
              v-show="item.attr == '其它'"
              style="background: #406de9"
            >
              其它
            </p>
            <p
              class="status"
              v-show="item.attr == '基础'"
              style="background: #39d578"
            >
              基础
            </p>
            <p
              class="status"
              v-show="item.attr == '实战'"
              style="background: #e94040"
            >
              实战
            </p>
            <el-checkbox class="checked" :label="item.pid" v-model="item.pid" />
          </div>
        </div>
      </div>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "CollectCourseDetail",
  props: ["collectList"],
  data() {
    return {
      checkList: [],
    };
  },
  methods: {
    checkAll() {
      if (this.collectList.length == this.checkList.length) {
        this.checkList = [];
      } else {
        this.collectList.forEach((item) => {
          if (this.checkList.indexOf(item.pid) == -1) {
            this.checkList.push(item.pid);
          }
        });
      }
    },
    clearAll() {
      this.checkList = [];
    },
  },

  watch: {
    checkList: {
      handler(newVal) {
        this.$emit("isAll", newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
::v-deep .el-checkbox__label {
  display: none;
}

.courseList {
  margin-top: 0.2rem;
  margin-right: -0.25rem;
}

.courseList:after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

.botStatus >>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f46600;
}

.botStatus >>> .el-checkbox__inner {
  border: 1px solid #f46600;
}

.status {
  color: #ffffff;
  font-size: 0.12rem;
  background: #406de9;
  width: 0.35rem;
  line-height: 0.2rem;
  height: 0.2rem;
  border-radius: 0.02rem;
  text-align: center;
}

.botStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.1rem;
}

.itemImg {
  width: 100%;
  height: 1.5rem;
  overflow: hidden;
  background: coral;
}

.itemImg img {
  width: 100%;
}

.itemBottom {
  flex: 1;
  padding: 0.1rem;
}

.checked {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  -webkit-transition: 500ms;
  -moz-transition: 500ms;
  -o-transition: 500ms;
}

.courseItem.active .checked {
  visibility: visible;
  opacity: 1;
}

.courseItem:hover .checked {
  visibility: visible;
  opacity: 1;
}

.courseItem:hover {
  border-bottom: 0.02rem solid #ff9e58;
}

.courseItem {
  width: 23.3%;
  height: 2.2rem;
  overflow: hidden;
  border-radius: 0.04rem 0.04rem 0 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  float: left;
  cursor: pointer;
  border-bottom: 0.02rem solid #eeeeee;
  -webkit-transition: 500ms;
  -moz-transition: 500ms;
  -o-transition: 500ms;
  transition: 500ms;
  margin-right: 0.15rem;
  margin-bottom: 0.2rem;
}
</style>